@use '../variables.scss';

.state-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;

  .title {
    font-size: 40px;
    display: inline-block;
    margin-right: 10px;
    line-height: 100%;
    white-space: nowrap;
    margin-bottom: 10px;
  }

  .actions-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.date-widgets-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  // Indicates a Bidding Event exists
  &.true {
    justify-content: flex-start;

    .time-range-display {
      border-left: 1px solid $light-grey;
      padding-left: 20px;

      @media screen and (max-width: 1400px) {
        border-color: transparent;
      }
    }
  }

  .reschedule-event {
    color: $theme-link-color;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    display: block;
    width: 100%;
    cursor: pointer;
  }

  .arrow {
    color: $light-grey;

    // Indicates there is a Bidding Event
    &.true {
      display: block;

      @media screen and (min-width: 1399px) {
        display: none;
      }
    }

    @media screen and (max-width: 1400px) {
      margin: 0 auto;
      transform: rotate(90deg);
    }
  }

  .request-date-widget {
    @media screen and (max-width: 1400px) {
      width: 100%;

      .date-container {
        .date,
        .time {
          font-size: 14px;
          display: inline-block;
        }

        .date:after {
          content: ', ';
          margin-right: 5px;
        }
      }
    }
  }

  .bidding-event-container {
    @media screen and (max-width: 1400px) {
      display: block;
      width: 100%;
    }

    a {
      &.disabled {
        opacity: .6;
        color: $light-grey;
      }
    }
  }
}
