.str-chat {
  height: auto;

  & * {
    font-family: 'Roboto', Helvetica, sans-serif !important;
  }

  &.str-chat-channel {
    max-height: 90vh !important;
    min-width: 300px;

    @media screen and (max-width: $mediumBreak) {
      min-width: 500px;
    }

    @media screen and (max-width: $smallBreak) {
      min-width: 300px;
    }
  }

  &.str-chat-channel-list {
    float: none;
    background: white;
    width: 360px;
    max-width: 360px;

    &.messaging {
      background: white;
    }

    .group-header {
      padding: 24px 25px 6px;
      font-size: 14px;
      color: $black;
      border-bottom: 1px solid #e5e9ed;

      &:not(:first-of-type) {
        background: #fcfcfc;
      }
    }

    .group-channels {
      // Stream auto-generates a p tag with a default message for empty channels
      > p {
        font-weight: 600;
        color: $black;
        padding: 30px 25px;
      }
    }

    .add-new-channel-wrapper {
      padding: 20px 15px 10px;
    }
  }
}
