.chat-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .error-boundary {
    margin: 15px 15px;
    width: 100%;
  }

  .chat-channels-container {
    background-color: $white;

    .channel-lists-wrapper {
      @media all and (max-width: 960px) {
        display: none;
      }
    }
  }
}
