@use 'sass:color';
@use './icons';
@import '@tokens';

@mixin light {
  --ht-font-size: 14px;
  --ht-line-height: 38px;
  --ht-font-weight: 400;
  --ht-gap-size: 4px;
  --ht-icon-size: 16px;
  --ht-table-transition: 0.15s;
  --ht-border-color: #{rgba($gray13, 0.06)};
  --ht-accent-color: #1a42e8;
  --ht-foreground-color: #222222;
  --ht-background-color: #ffffff;
  --ht-placeholder-color: #999999;
  --ht-read-only-color: #a3a3a3;
  --ht-disabled-color: #a3a3a3;
  --ht-cell-horizontal-border-color: #{rgba($gray13, 0.06)};
  --ht-cell-vertical-border-color: #{rgba($gray13, 0.06)};
  --ht-wrapper-border-width: 0;
  --ht-wrapper-border-radius: 4px;
  --ht-wrapper-border-color: #e7e7e9;
  --ht-row-header-odd-background-color: #{$gray1};
  --ht-row-header-even-background-color: #{$gray1};
  --ht-row-cell-odd-background-color: rgba(34, 34, 34, 0);
  --ht-row-cell-even-background-color: rgba(34, 34, 34, 0);
  --ht-cell-horizontal-padding: 8px;
  --ht-cell-vertical-padding: 4px;
  --ht-cell-editor-border-width: 2px;
  --ht-cell-editor-border-color: #{$blue3};
  --ht-cell-editor-foreground-color: #000000;
  --ht-cell-editor-background-color: #ffffff;
  --ht-cell-editor-shadow-blur-radius: 0;
  --ht-cell-editor-shadow-color: #{$blue1};
  --ht-cell-success-background-color: rgba(55, 188, 108, 0.2);
  --ht-cell-error-background-color: rgba(250, 77, 50, 0.2);
  --ht-cell-selection-border-color: #{$blue3};
  --ht-cell-selection-background-color: #{$blue1};
  --ht-cell-autofill-size: 6px;
  --ht-cell-autofill-border-width: 1px;
  --ht-cell-autofill-border-radius: 4px;
  --ht-cell-autofill-border-color: #ffffff;
  --ht-cell-autofill-background-color: #1a42e8;
  --ht-cell-autofill-fill-border-color: #222222;
  --ht-resize-indicator-color: rgba(34, 34, 34, 0.4);
  --ht-move-backlight-color: rgba(34, 34, 34, 0.06);
  --ht-move-indicator-color: #1a42e8;
  --ht-hidden-indicator-color: rgba(34, 34, 34, 0.4);
  --ht-scrollbar-border-radius: 8px;
  --ht-scrollbar-track-color: #f7f7f9;
  --ht-scrollbar-thumb-color: #999999;
  --ht-checkbox-size: 16px;
  --ht-checkbox-border-radius: 4px;
  --ht-checkbox-border-color: #d1d1d5;
  --ht-checkbox-background-color: #ffffff;
  --ht-checkbox-icon-color: rgba(255, 255, 255, 0);
  --ht-checkbox-focus-border-color: #d1d1d5;
  --ht-checkbox-focus-background-color: #ffffff;
  --ht-checkbox-focus-icon-color: rgba(255, 255, 255, 0);
  --ht-checkbox-focus-ring-color: #1a42e8;
  --ht-checkbox-disabled-border-color: #e7e7e9;
  --ht-checkbox-disabled-background-color: #d1d1d5;
  --ht-checkbox-disabled-icon-color: rgba(255, 255, 255, 0);
  --ht-checkbox-checked-border-color: #3f60ec;
  --ht-checkbox-checked-background-color: #1a42e8;
  --ht-checkbox-checked-icon-color: #ffffff;
  --ht-checkbox-checked-focus-border-color: #ffffff;
  --ht-checkbox-checked-focus-background-color: #1a42e8;
  --ht-checkbox-checked-focus-icon-color: #ffffff;
  --ht-checkbox-checked-disabled-border-color: #e7e7e9;
  --ht-checkbox-checked-disabled-background-color: #d1d1d5;
  --ht-checkbox-checked-disabled-icon-color: #a3a3a3;
  --ht-header-font-weight: 00;
  --ht-header-foreground-color: #222222;
  --ht-header-background-color: #{rgba($gray13, 0.02)};
  --ht-header-active-border-color: #{$blue3};
  --ht-header-active-foreground-color: #{$gray13};
  --ht-header-active-background-color: #{$blue1};
  --ht-header-highlighted-shadow-size: 0;
  --ht-header-highlighted-foreground-color: #000000;
  --ht-header-highlighted-background-color: #{$blue1};
  --ht-header-filter-background-color: rgba(55, 188, 108, 0.2);
  --ht-header-row-foreground-color: #222222;
  --ht-header-row-background-color: #f7f7f9;
  --ht-header-row-highlighted-foreground-color: #000000;
  --ht-header-row-highlighted-background-color: #{$blue1};
  --ht-header-row-active-foreground-color: #{$gray13};
  --ht-header-row-active-background-color: #{$blue1};
  --ht-icon-button-border-radius: 2px;
  --ht-icon-button-border-color: #f7f7f9;
  --ht-icon-button-background-color: #f7f7f9;
  --ht-icon-button-icon-color: rgba(34, 34, 34, 0.4);
  --ht-icon-button-hover-border-color: #e7e7e8;
  --ht-icon-button-hover-background-color: #e7e7e8;
  --ht-icon-button-hover-icon-color: rgba(34, 34, 34, 0.4);
  --ht-icon-active-button-border-color: #2e56fc;
  --ht-icon-active-button-background-color: #1a42e8;
  --ht-icon-active-button-icon-color: #ffffff;
  --ht-icon-active-button-hover-border-color: #2e56fc;
  --ht-icon-active-button-hover-background-color: #3357ea;
  --ht-icon-active-button-hover-icon-color: #ffffff;
  --ht-collapse-button-border-radius: 4px;
  --ht-collapse-button-open-border-color: #d1d1d5;
  --ht-collapse-button-open-background-color: #ffffff;
  --ht-collapse-button-open-icon-color: #666666;
  --ht-collapse-button-open-icon-active-color: #666666;
  --ht-collapse-button-open-hover-border-color: #d1d1d5;
  --ht-collapse-button-open-hover-background-color: #f7f7f9;
  --ht-collapse-button-open-hover-icon-color: #666666;
  --ht-collapse-button-open-hover-icon-active-color: #666666;
  --ht-collapse-button-close-border-color: #d1d1d5;
  --ht-collapse-button-close-background-color: #ebebed;
  --ht-collapse-button-close-icon-color: #666666;
  --ht-collapse-button-close-icon-active-color: #666666;
  --ht-collapse-button-close-hover-border-color: #d1d1d5;
  --ht-collapse-button-close-hover-background-color: #ebebed;
  --ht-collapse-button-close-hover-icon-color: #666666;
  --ht-collapse-button-close-hover-icon-active-color: #666666;
  --ht-button-border-radius: 4px;
  --ht-button-horizontal-padding: 12px;
  --ht-button-vertical-padding: 6px;
  --ht-primary-button-border-color: rgba(255, 255, 255, 0);
  --ht-primary-button-foreground-color: #ffffff;
  --ht-primary-button-background-color: #1a42e8;
  --ht-primary-button-disabled-border-color: rgba(255, 255, 255, 0);
  --ht-primary-button-disabled-foreground-color: #a3a3a3;
  --ht-primary-button-disabled-background-color: rgba(153, 153, 153, 0.32);
  --ht-primary-button-hover-border-color: rgba(255, 255, 255, 0);
  --ht-primary-button-hover-foreground-color: #ffffff;
  --ht-primary-button-hover-background-color: #1535bc;
  --ht-primary-button-focus-border-color: #ffffff;
  --ht-primary-button-focus-foreground-color: #ffffff;
  --ht-primary-button-focus-background-color: #1a42e8;
  --ht-secondary-button-border-color: #e7e7e9;
  --ht-secondary-button-foreground-color: #222222;
  --ht-secondary-button-background-color: #ffffff;
  --ht-secondary-button-disabled-border-color: #e7e7e9;
  --ht-secondary-button-disabled-foreground-color: #a3a3a3;
  --ht-secondary-button-disabled-background-color: #ebebed;
  --ht-secondary-button-hover-border-color: #e7e7e9;
  --ht-secondary-button-hover-foreground-color: #222222;
  --ht-secondary-button-hover-background-color: #ffffff;
  --ht-secondary-button-focus-border-color: #e7e7e9;
  --ht-secondary-button-focus-foreground-color: #222222;
  --ht-secondary-button-focus-background-color: #ffffff;
  --ht-comments-textarea-horizontal-padding: 8px;
  --ht-comments-textarea-vertical-padding: 4px;
  --ht-comments-indicator-size: 6px;
  --ht-comments-indicator-color: #1a42e8;
  --ht-comments-textarea-border-width: 1px;
  --ht-comments-textarea-border-color: rgba(255, 255, 255, 0);
  --ht-comments-textarea-foreground-color: #222222;
  --ht-comments-textarea-background-color: #f7f7f9;
  --ht-comments-textarea-focus-border-width: 1px;
  --ht-comments-textarea-focus-border-color: #1a42e8;
  --ht-comments-textarea-focus-foreground-color: #222222;
  --ht-comments-textarea-focus-background-color: #ffffff;
  --ht-license-horizontal-padding: 16px;
  --ht-license-vertical-padding: 8px;
  --ht-license-foreground-color: #222222;
  --ht-license-background-color: #f7f7f9;
  --ht-link-color: #1a42e8;
  --ht-link-hover-color: #3e67ec;
  --ht-input-border-width: 1px;
  --ht-input-border-radius: 4px;
  --ht-input-horizontal-padding: 12px;
  --ht-input-vertical-padding: 6px;
  --ht-input-border-color: #e7e7e9;
  --ht-input-foreground-color: #222222;
  --ht-input-background-color: #f7f7f9;
  --ht-input-hover-border-color: #e7e7e9;
  --ht-input-hover-foreground-color: #222222;
  --ht-input-hover-background-color: #ffffff;
  --ht-input-disabled-border-color: #e7e7e9;
  --ht-input-disabled-foreground-color: #a3a3a3;
  --ht-input-disabled-background-color: rgba(153, 153, 153, 0.32);
  --ht-input-focus-border-color: #1a42e8;
  --ht-input-focus-foreground-color: #222222;
  --ht-input-focus-background-color: #ffffff;
  --ht-menu-border-width: 1px;
  --ht-menu-border-radius: 4px;
  --ht-menu-horizontal-padding: 0;
  --ht-menu-vertical-padding: 8px;
  --ht-menu-item-horizontal-padding: 12px;
  --ht-menu-item-vertical-padding: 4px;
  --ht-menu-shadow-x: 0;
  --ht-menu-shadow-y: 8px;
  --ht-menu-shadow-blur: 16px;
  --ht-menu-border-color: #e7e7e9;
  --ht-menu-shadow-color: rgba(0, 0, 0, 0.08);
  --ht-menu-item-hover-color: rgba(0, 0, 0, 0.05);
  --ht-menu-item-active-color: rgba(0, 0, 0, 0.08);
}

.ht-theme-line-items,
.ht-theme-line-items-dark,
.ht-theme-line-items-dark-auto {
  @include light();
}

/* Icons */
[class*='ht-theme-line-items'] {
  @include icons.output();
}

// Custom overrides
.handsontable.ht-theme-line-items {
  td,
  th {
    vertical-align: middle;
  }

  td:last-child {
    background: $gray1;
  }

  td:last-child:not([role='rowheader']):not([role='row']):not(
      [role='columnheader']
    ),
  th:last-child:not([role='rowheader']):not([role='row']):not(
      [role='columnheader']
    ) {
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
    position: sticky;
    right: 0;
    z-index: 12;
  }

  tbody {
    tr {
      td {
        background-color: rgba($gray13, 0.02);
        border-right: 0;

        &:first-of-type {
          background-color: $gray1;
          border-right: 1px solid rgba($gray13, 0.06);
        }
      }
    }
  }

  thead {
    tr {
      th {
        padding: 6px 10px;
      }
      &:nth-child(2) {
        th {
          font-weight: 700;
          text-align: left;

          &:not(:first-child):not(:nth-child(2)) {
            background-color: $gray3;

            &.ht__active_highlight {
              background-color: $blue1;
            }
          }
        }
      }

      &:first-of-type {
        th {
          &:last-child {
            box-shadow: none;
            position: relative;
          }
        }
      }

      &:nth-child(2) {
        th {
          &:last-child:not([role='row']) {
            box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
            position: sticky;
            right: 0;
            z-index: 12;
          }
        }
      }
    }
  }

  .htNumeric {
    text-align: left;
  }

  .ht_master > .wtHolder > .wtHider {
    margin-bottom: 58px;
  }
}
