#channel-group-members {
  border-bottom: 1px solid #e5e9ed;

  .ant-collapse,
  .ant-collapse > .ant-collapse-item:last-child,
  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0;
    border: none;

    .ant-collapse-header {
      background: white;
      color: #9fa9ba;
      border: none;
      font-weight: 600;
      font-size: 14px;
      padding: 15px 20px;

      i {
        font-size: 16px;
        right: 20px;
      }
    }

    .ant-collapse-content-box {
      padding-top: 10px;
    }
  }
}
