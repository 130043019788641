
#anchor-preview {
  .disable-suggested-pricing-toggle.ant-switch.ant-switch-checked {
    background-color: $theme-color;
  }

  .search-bar  {
    margin: {
      top: 15px;
      bottom: 20px;
    }
  }
}
