.str-chat-channel {
  &.messaging {
    &.str-chat {
      // Main message list container
      .str-chat__list {
        padding: {
          left: 20px;
          right: 20px;
        }

        background: #F5F6FB;

        ul.str-chat__ul {
          li.str-chat__li {
            .str-chat__message {
              p, .str-chat__message-simple p {
                overflow-wrap: anywhere;
              }

              margin: {
                top: 5px;
                bottom: 5px;
              }
              .str-chat__message-url-link {
                text-wrap: wrap;
              }

              display: flex;
              flex-direction: row;

              &.str-chat__message--with-reactions {
                margin-top: 30px;
              }

              .str-chat__avatar {
                flex-grow: 0;
              }

              .str-chat__message-inner {
                flex-grow: 1;

                .str-chat__message-text {
                  display: flex;

                  .str-chat__message-simple-text-inner {
                    max-width: 100%;
                    width: 100%;
                    border-radius: 4px;
                  }
                }
              }
            }
          }
        }
      }

      .str-chat__main-panel {
        padding: 0;

        .str-chat__header-livestream {
          border-radius: 0;
          height: 90px;
        }
      }
    }
  }
}
