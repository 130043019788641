.chat-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  @media all and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .error-boundary {
    margin: 15px 15px;
    width: 100%;
  }
}
